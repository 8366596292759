.section2{
    background-color: #007a41;
    color:white;
    line-height: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 padding:70px 0;

}
.section2 h2{
    text-align: center;
   margin-bottom: 1em;
}
.section2 h6{
    text-align: center;
   margin:0 6em;
   line-height: 2em;
}