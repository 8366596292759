.listcontainer {
list-style: none;

}
.lists {
    color:#64748B;
    font-weight: 700;
    line-height: 2em;
    font-size: 1.3em;
    cursor: pointer;
    text-align: center;
}