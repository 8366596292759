
    /*backgroundImage:`url(${image})`,
     
}*/

.frontpage {
    
    display: block;
    position: relative;
  }
  
  .frontpage::after {
    content: "";
    background-image: url('./images/Football.png');
    
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1; 
    background-size: cover;
    background-position:top;
    background-repeat: no-repeat;  
  }