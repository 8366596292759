/* 
=========================
Fonts
=========================

font-family: 'Outfit', sans-serif;

*/

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap');

/* 
========================
Variables
========================
*/


:root {
    
    font-size: 15px;
    
    /* Primary */
    --var-soft-blue: hsl(215, 51%, 70%);
    --var-cyan: hsl(178, 100%, 50%);
    /* Neutral */
    --var-main-darkest: #011f1b;
    --var-card-dark: #0a7d6e;
    --var-line-dark:#0dbba4;
    --var-lightest: white;
    
    /* Fonts */
    
    --var-heading: normal normal 600 1.5em/1.6em 'Outfit', sans-serif;
    
    --var-small-heading: normal normal 400 1em/1em 'Outfit', sans-serif;
    
    --var-para: normal normal 300 1em/1.55em 'Outfit', sans-serif;
}

/* 
=======================
Setup
=======================
*/

html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
    margin: 0;
}



img {
    width: 100%;
    border-radius: 15px;
    display: block;
}

a {
    color: inherit;
}



.bookingcard-heading {
    font: var(--var-heading);
    color: var(--var-lightest);
    padding: 1.2em 0;
}

.bookingcard-heading2 {
    font: var(--var-small-heading);
    color: var(--var-lightest);
    /* padding on .coin-base */
}

p {
    font: var(--var-para);
    color: var(--var-soft-blue);
}

span {
    color: white;
}
/* 
=====================
Classes
=====================
*/

/* LAYOUT */

.card-container {
  
   max-width: 300px;
    
 background-color: var(--var-card-dark);
    border-radius: 15px;
    margin-bottom: 1rem;
    padding: 2rem;
}

div.flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.coin-base, .time-left, .card-attribute {
    display: flex;
    align-items: center;
    padding: 1em 0;
}

.card-attribute {
    padding-bottom: 1.5em;
    border-top: 2px solid var(--var-line-dark);
}

a.hero-image-container {
    position: relative;
    display: block;
}







.hero-image-container::after {
    content: '';
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: 5rem;
    background-color: hsla(178, 100%, 50%, 0.3);
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.hero-image-container:hover::after {
  opacity: 1;
}

.small-image {
    width: 1.2em;
    margin-right: .5em;
}

.small-avatar {
    width: 2em;
    border-radius: 200px;
    outline: 2px solid white;
    margin-right: 1.4em;
}

div.attribution {
    margin: 0 auto;
    width: 100%;
    font: var(--var-para);
    text-align: center;
    padding: 1.5em 0 4em 0;
    color: var(--var-line-dark);
}
.attribution a {
    color: var(--var-soft-blue);
}

