.carousal-text{
    
    height:100px;
    background: linear-gradient(rgb(0, 0, 0,.5),rgba(255, 255, 255, 0.5));
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    mix-blend-mode:luminosity;
}