.pagination {
    height: 50px;
background-color: white;
overflow: hidden;
border: none;
box-shadow: none;
display: flex;
align-items: center;
justify-content: center;
}
